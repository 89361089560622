<template>
<div id="track-order">
    <NavBar />
    <TrackOrderComponent />
    <GetInTouch />
    <TheFooter />
</div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import TheFooter from '@/components/TheFooter.vue';
import TrackOrderComponent from '@/components/user/TrackOrderComponent.vue';
import GetInTouch from '@/components/user/GetInTouch.vue';

export default {
	components: {
        NavBar,
		TheFooter,
        TrackOrderComponent,
        GetInTouch
	}
}
</script>

<style scoped>

</style>