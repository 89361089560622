<template>
<div id="trackOrder">
    <div class="container flex center">

        <form class="flex" @submit.prevent="trackOrder" v-if="!order">
            <h2 class="sub-headings centered">
                Track <span>Order</span>
            </h2>

            <div class="form-element">
                <input
                    type="text"
                    id="orderID"
                    class="form-input order-id"
                    placeholder="Order ID"
                    v-model="orderID"
                    data-name = 'order Id'
                    data-len = '10'
                    @input="handleInput" @blur="validateFields"
                    />
            </div>

            <button type="submit" class="btn submit-btn">
                Track
            </button>

        </form>

        <div class="track-outer flex" v-if="order">

            <span class="close-icon" @click="order=null"></span>

            <h2 class="sub-headings centered"> Order Status </h2>

            <p v-if="!order.fixed">
                We are working on your order. It's not fixed.
            </p>

            <p v-if="order.fixed && !order.delivered ">
                Your order is fixed and it is ready to deliver.
            </p>

            <p v-if="order.fixed && order.delivered ">
                Already delivered.
            </p>

            <div class="track-table flex">
                <div class="track-row header-row flex">
                    <span class="order-id"> Order ID </span>
                    <span class="brand"> Brand </span>
                    <span class="model"> Model </span>
                    <span class="c-name"> Customer Name </span>
                    <span class="status"> status </span>
                    <span class="del-date"> Expected Delivery Date </span>
                </div>
                <div class="track-row body-row flex">
                    <span class="order-id"> {{ order.orderID }} </span>
                    <span class="brand"> {{ order.brand }} </span>
                    <span class="model"> {{ order.model }} </span>
                    <span class="c-name"> {{ order.customerName }} </span>
                    <span class="status" :class=" classObj ">
                        <i>
                            {{ status }}
                        </i>
                    </span>
                    <span class="del-date"> {{ deliveryDate }} </span>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import $ from 'jquery';
import { Orders } from '../../services/Service'

export default {
    data() {
        return {
            orderID: '',
            order: null
        }
    },
    methods:
    {
        handleInput(e)
        {
            if( e.target.value === '' )
                this.addErrorMessage( e.target.id );
            else
                this.removeErrorMessage( e.target.id );
        },
        validateFields({target: el})
        {
            const { name, len } = el.dataset;
            if(el.value === '')
                this.addErrorMessage( el.id );
            else if( el.value.length < len )
                this.addErrorMessage( el.id, `${name} must have atleast ${len} digits.`);
        },
        addErrorMessage( id, msg )
        {
            $(`.${id}-error-msg`).remove();
            var contents;
            if(msg)
                contents = `<p class="error-msg ${id}-error-msg">${msg}</p>`;
            else
                contents = `<p class="error-msg ${id}-error-msg">${$(`#${id}`).data('name')} can't be empty </p>`;
            $(`#${id}`).parent().addClass('error-parent').append(contents);
        },
        removeErrorMessage(id)
        {
            $(`.${id}-error-msg`).remove();
            $(`#${id}`).parent().removeClass('error-parent');
        },
        async trackOrder() {
            if( this.orderID === '' )
                this.addErrorMessage('orderID');
            else if( this.orderID.length !== 10 )
                this.addErrorMessage('orderID', 'orderID must be 10 digits.' );

            if( $('#trackOrder .error-msg').length ) return;
            $('.submit-btn').blur();
            $('.submit-btn').html(`<div class="spinner-border" role="status"></div>`);
            try {
                const response = await Orders.getByID({
                    orderID: this.orderID
                });

                if( response.status === 200 )
                {
                    this.orderID = '';
                    $('.submit-btn').html(`track`);

                    if( response.data.result )
                    {
                        this.order = response.data.result;
                    }
                    else
                    {
                        const notification = {
                            type: 'error',
                            message: 'Order ID not found.'
                        };
                        this.$store.dispatch('addNotification', notification);
                    }
                }
            } catch (error)
            {
                const { message } = error.response.data;
                const notification = { type: 'error', message }
                $('.submit-btn').html(`track`);
                this.$store.dispatch('addNotification', notification);
            }
        },
    },
    computed: {
        deliveryDate() {
                var date = this.order.expectedDeliveryDate;
                date = typeof date === "string" ? new Date(date) : date;
                return date.toLocaleDateString('en-IN', { year: 'numeric', month: 'long', day: 'numeric' });
        },
        status() {
            return !this.order.fixed ? "active" : this.order.delivered ? "delivered" : "fixed";
        },
        classObj() {
            return {
                "active": this.status === "active",
                "fixed": this.status === "fixed",
                "delivered": this.status === "delivered"
            }
        }
    }
}
</script>

<style scoped>

#trackOrder
{
    background-image: url('../../assets/Background Images/background_2.jpg');
    background-size: cover;
    position: relative;
    z-index: 1;
    color: #fff;
}

.container
{
    flex-direction: column;
    padding: 100px 15px;
    min-height: 650px;
    height: calc(100vh - 100px);
}

#trackOrder::before
{
    content: '';
    position: absolute;
    top: 0; left: 0;
    bottom: 0; right: 0;
    background-color: rgb(0, 0, 0,0.35);
    z-index: -1;
}

form
{
    flex-direction: column;
    align-items: center;
    width: 450px;
    background-color: rgb(0, 0, 0,0.75);
    padding: 50px;
    margin: 0 auto;
    box-shadow: 3px 3px 18px rgb(255, 255, 255,0.1);
}

form>* { margin-bottom: 20px; }

.form-element
{
    width: 350px;
}

.form-input
{
    height: 50px; width: 100%;
    border: 2px solid #E20613;
    background-color: rgb(0, 0, 0,0.7);
    box-shadow: 3px 3px 4px rgb(255, 255, 255,0.15);
    outline: 0;
    padding-left: 18px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
}

.form-input::placeholder { color: #fff; }

.form-input:focus { border: 2px solid white; }

.submit-btn
{
    height: 50px; width: 350px;
    border-radius: 3px;
    box-shadow: 0px 0px 6px rgb(255, 255, 255,0.2);
    transition: all 0.1s ease;
    border: 2px solid transparent;
    background-color: #E20613;
    font-weight: 600;
    font-size: 17px;
    color: #ffffff;
    margin: 10px 0 0;
    text-transform: uppercase;
}

.submit-btn:hover
{
    background-color: rgb(0, 0, 0,0.25);
    border: 2px solid #E20613;
}

h3
{
    font-weight: 700;
    font-size: 24px;
}

/* Track Order Box */

.track-outer
{
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1024px;
    background-color: rgb(0, 0, 0,0.75);
    padding: 50px;
    margin: 0 auto;
    box-shadow: 3px 3px 18px rgb(255, 255, 255,0.1);
}

.track-outer p
{
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
    font-size: 22px;
    font-weight: 600;
}

.track-table
{
    width: 100%;
    flex-direction: column;
}

.track-row
{
    width: 100%;
    border: 2px solid #fff;
    height: 75px;
    align-items: center;
    padding: 0 15px;
}

.header-row { border-bottom: 0; text-transform: capitalize; height: 60px; }

.track-row span
{
    width: calc(100% / 6);
}

.track-row span.order-id, .track-row span.brand, .track-row span.model
{ width: 120px; }

.track-row span.del-date { width: 200px; }

.status { padding: 0; }

.status i
{
    padding: 10px 20px;
    display: inline-block;
    border-radius: 15px;
    color: whitesmoke;
    font-style: normal;
    text-transform: capitalize;
    line-height: 1;
}

.status.fixed i { background-color: #00917c; }

.status.active i { background-color: #51c2d5; }

.status.delivered i { background-color: #ff9a76; }

.close-icon
{
    position: absolute;
    right: 25px; top: 25px;
    cursor: pointer;
    width: 30px; height: 30px;
}

.close-icon::before, .close-icon::after
{
    content: '';
    position: absolute;
    width: 3px; height: 30px;
    top: 0; left: 50%;
    background-color: white;
}

.close-icon::before { transform: translateX(-50%) rotate(45deg); }

.close-icon::after { transform: translateX(-50%) rotate(-45deg); }

.close-icon:hover { opacity: 0.5; }


@media (max-width: 1023px)
{
    #trackOrder { background-position-x: -200px; }

    .container { min-height: auto; height: auto;  }


    /* Track order */

    .track-table
    {
        flex-direction: row;
        height: auto;
        width: 100%;
        max-width: 600px;
    }

    .track-row
    {
        flex-direction: column;
        width: 50%;
        height: auto;
        padding: 20px 15px;
    }

    .header-row { border-bottom: 2px solid #fff; }

    .track-row span
    {
        width: 100% !important;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.5;
        text-align: center;
    }
}

@media (max-width: 767px)
{
    #trackOrder { background-position-x: -300px; }

    .track-outer
    {
        padding: 0;
        background-color: transparent;
        box-shadow: none;
    }

    .close-icon { top: -50px; }
}

@media (max-width: 500px)
{
    form { width: 100%; padding: 50px 0; background-color: transparent; box-shadow: none; }

    .form-element, .submit-btn { width: 100%; }

    .track-row span { height: 75px; }
}

@media (max-width: 375px)
{
    #trackOrder { background-position-x: -400px; }
}

</style>